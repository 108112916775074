import Bitrix from '../../../plugins/Bitrix'

export default {
    actions: {
        async addPlanning(ctx, data) {
            try {
                const {
                    users,
                    planningDates,
                    planningType,
                    company,
                    contactInfo,
                    deal,
                    description,
                    files: loadFiles
                } = data

                const files = await Bitrix.loadFiles(loadFiles)

                const params = {
                    IBLOCK_TYPE_ID: 'lists',
                    IBLOCK_ID: 47,
                    ELEMENT_CODE: Date.now(),
                    FIELDS: {
                      NAME: 'Планирование в график отсутствий',
                      PROPERTY_235: users, // кого запланировать
                      PROPERTY_237: planningDates[0], // дата с
                      PROPERTY_239: planningDates[1] || planningDates[0], // дата по
                      PROPERTY_325: planningType, // тип планирования,
                      PROPERTY_1975: company, // компания
                      PROPERTY_1977: contactInfo, // контактная информация
                      PROPERTY_241: deal ? deal.id : '', // сделка
                      PROPERTY_301: description, // планируемые работы
                      PROPERTY_1979: files, // файлы
                      PROPERTY_1981: 'application'
                    }
                }
                const res = await Bitrix.callMethod('lists.element.add', params)

                return { elementId: res }
            } catch (e) {
                return e
            }
        }
    }
}
